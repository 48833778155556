module.exports = {
  footerText: 'Copyright The Church of Pentecost U.S.A., Inc.  © 2023',

  // baseurl: 'http://localhost:3000/',
  // apiserver: 'http://localhost:8000/api/',

   baseurl: 'https://ticket.penterp.com',
   apiserver: 'https://helpapi.penterp.com/api/',

  profile_img: 'https://helpapi.penterp.com/public/upload/profile_image/',
  ticket_file: 'https://helpapi.penterp.com/public/upload/support/',
  reply_file: 'https://helpapi.penterp.com/public/upload/reply/',
  cislogo: 'https://ticket.penterp.com/public/assets/images/icon.png',

  // cislogo: 'http://localhost:3000/public/assets/images/icon.png',
  // ticket_file: 'http://localhost:8000/upload/support/',
  // reply_file: 'http://localhost:8000/upload/reply/',



  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'The Church of Pentecost U.S.A., Inc.',
  address : '',
  city : 'Dhaka',
  zipcode : '1206',
  phone : "+8801670228961",
  email : "info@copusa.org",
  website : "https://copusa.org"
}
