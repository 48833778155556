import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnMount: true,
        },
    },
});

const store = configureStore(/* provide initial state if any */);

const NextApp = () => (
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
);

export default NextApp;
