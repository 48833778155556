import { Row, Layout } from "antd";
import { useSelector } from "react-redux";
import HomeCard from "../../components/HomePage/HomeCard";

const Home = () => {
    const { authUser } = useSelector(({ auth }) => auth);

    return (
        <>
            <main className="ant-layout-content gx-layout-content gx-container-wrap ">
                <div className="home-main-content">
                    <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                        <div className="gx-algolia-main">
                            <h1 className="home-top-header">
                                Welcome Back, {authUser.name}
                            </h1>

                            <Row gutter={24}>
                                <HomeCard />
                            </Row>
                        </div>
                    </Layout>
                </div>
            </main>
        </>
    );
};

export default Home;
