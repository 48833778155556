import React from "react";
import { Breadcrumb, Input, Layout, Card, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

const { Search } = Input;
const onSearch = (value) => console.log(value);

const columns = [
    {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
    },
    {
        title: "Invoice",
        dataIndex: "invoice",
        key: "invoice",
    },
    {
        title: "Invoice Date",
        dataIndex: "invoiedate",
        key: "invoiedate",
    },
    {
        title: "Due Date",
        dataIndex: "duedate",
        key: "duedate",
    },
    {
        title: "Total",
        dataIndex: "total",
        key: "total",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
];

const Invoices = () => {
    const data = [];

    return (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Invoices</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Invoices</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                <Card className="tickets-card">
                                    <Card
                                        type="inner"
                                        title="Showing 1 to 1 of 1 entries"
                                        extra={
                                            <Search
                                                placeholder="input search text"
                                                onSearch={onSearch}
                                            />
                                        }
                                    >
                                        <Table
                                            scroll={{ x: true }}
                                            columns={columns}
                                            dataSource={data}
                                        />
                                    </Card>
                                </Card>
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default Invoices;
