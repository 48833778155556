import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import Config from "../../util/config";

const UserInfo = () => {
    const dispatch = useDispatch();
    const { authUser } = useSelector(({ auth }) => auth);

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </ul>
    );

    return (
        <div className="gx-flex-row gx-align-items-center gx-avatar-row">
            <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={userMenuOptions}
                trigger="click"
            >
                {authUser?.image != null && (
                    <Avatar
                        src={Config.profile_img + authUser?.image}
                        className="gx-pointer"
                        alt=""
                        style={{ width: "40px", height: "40px" }}
                    />
                )}
                <span
                    className="gx-avatar-name"
                    style={{
                        color: "#000",
                        fontSize: "14px",
                        marginLeft: "10px",
                    }}
                >
                    {authUser?.name}
                    <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
                </span>
            </Popover>
        </div>
    );
};

export default UserInfo;
