import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "../reducers";
import { composeWithDevTools } from "@redux-devtools/extension";

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

export default function configureStore(preloadedState = {}) {
    return createStore(
        createRootReducer(history),
        preloadedState,
        compose(composeWithDevTools(
          applyMiddleware(routerMiddleware(history), ...middlewares)
        ))
    );
}
