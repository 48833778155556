import React from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import { Button } from "antd";

const Update = () => {
    return (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    {/*<Sider className="gx-algolia-sidebar">*/}
                    {/*	<SupportPin />*/}
                    {/*	<YourInfo />*/}
                    {/*	<HomeSideNav />*/}
                    {/*</Sider>*/}
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Update Account</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>Account Details</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                <Form>
                                    <Row
                                        className="ant-row"
                                        style={{ rowGap: "15px" }}
                                        justify="space-between"
                                    >
                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="fname"
                                                className="openticket-label"
                                            >
                                                First Name
                                            </label>
                                            <Input
                                                id="fname"
                                                placeholder="Name"
                                            />
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="lname"
                                                className="openticket-label"
                                            >
                                                Last Name
                                            </label>
                                            <Input
                                                id="lname"
                                                placeholder="Name"
                                            />
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="eaddress"
                                                className="openticket-label"
                                            >
                                                Email Address
                                            </label>
                                            <Input
                                                id="eaddress"
                                                placeholder="Name"
                                            />
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="cname"
                                                className="openticket-label"
                                            >
                                                Company Name
                                            </label>
                                            <Input
                                                id="cname"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="address1"
                                                className="openticket-label"
                                            >
                                                Address 1
                                            </label>
                                            <Input
                                                id="address1"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="address2"
                                                className="openticket-label"
                                            >
                                                Address 2
                                            </label>
                                            <Input
                                                id="address2"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="city"
                                                className="openticket-label"
                                            >
                                                City
                                            </label>
                                            <Input
                                                id="city"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="state"
                                                className="openticket-label"
                                            >
                                                State/Region
                                            </label>
                                            <Input
                                                id="state"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="zip"
                                                className="openticket-label"
                                            >
                                                Zip Code
                                            </label>
                                            <Input
                                                id="zip"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="country"
                                                className="openticket-label"
                                            >
                                                Country
                                            </label>
                                            <Input
                                                id="country"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="phone-number"
                                                className="openticket-label"
                                            >
                                                Phone Number
                                            </label>
                                            <Input
                                                id="phone-number"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="payment-method"
                                                className="openticket-label"
                                            >
                                                Payment Method
                                            </label>
                                            <Input
                                                id="payment-method"
                                                placeholder="Name"
                                            />
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="default-billing-contact"
                                                className="openticket-label"
                                            >
                                                Default Billing Contact
                                            </label>
                                            <Input
                                                id="default-billing-contact"
                                                placeholder="Name"
                                            />
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <label
                                                htmlFor="language"
                                                className="openticket-label"
                                            >
                                                Language
                                            </label>
                                            <Input
                                                id="language"
                                                placeholder="Name"
                                            />
                                        </Col>
                                        <Col
                                            lg={24}
                                            xs={24}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                type="secondary"
                                                htmlType="reset"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default Update;
