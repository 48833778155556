import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    UPDATE_LOAD_USER,
    USER_DATA,
    USER_PROFILE_DATA,
    USER_TOKEN_SET,
} from "../../constants/ActionTypes";
// import axios from '../../util/Api'
import axios from "../../util/AxiosInstance";
import { CisUI } from "../../util/CISUI";
import Config from "../../util/config";
import { notification, message } from "antd";

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url,
    };
};

export const userSignUp = ({ email, password, name }) => {
    console.log(email, password);
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post(Config.apiserver + "auth/register", {
                email: email,
                password: password,
                name: name,
            })
            .then(({ data }) => {
                console.log("data:", data);
                if (data.result) {
                    localStorage.setItem("token", data.token.access_token);
                    axios.defaults.headers.common["authorization"] =
                        "Bearer " + data.token.access_token;
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: USER_TOKEN_SET,
                        payload: data.token.access_token,
                    });
                    dispatch({ type: USER_DATA, payload: data.user });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    };
};

export const userSignIn = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post(Config.apiserver + "login", {
                userid: email,
                password: password,
            })
            .then(({ data }) => {
                // console.log("userSignIn: ", data);
                if (data.result) {
                    localStorage.setItem("token", data.token.access_token);
                    localStorage.setItem("userData", JSON.stringify(data.user));
                    localStorage.setItem("otpstatus", 1);
                    axios.defaults.headers.common["Authorization"] =
                        data.token.access_token;
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: USER_TOKEN_SET,
                        payload: data.token.access_token,
                    });
                    dispatch(getUser(data.token.access_token));
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.error });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    };
};

export const userSignInByKey = (key) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post(Config.apiserver + "remoteLogin", { key: key })
            .then(({ data }) => {
                // console.log("userSignIn: ", data);
                if (data.result) {
                    localStorage.setItem("token", data.token.access_token);
                    localStorage.setItem("userData", JSON.stringify(data.user));
                    localStorage.setItem("otpstatus", 1);
                    axios.defaults.headers.common["Authorization"] =
                        data.token.access_token;
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: USER_TOKEN_SET,
                        payload: data.token.access_token,
                    });
                    dispatch(getUser(data.token.access_token));
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.error });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    };
};

export const userSignInByToken = (token) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('loginbyems?tps=2&token='+token)
            .then(({ data }) => {
                console.log(data);
                if (data.result) {
                    localStorage.setItem("token", data.access_token);
                    localStorage.setItem("userData", JSON.stringify(data.user));
                    // localStorage.setItem("prms", data.prms);
                    // slocalStorage.setItem("otpstatus", 1);
                    axios.defaults.headers.common['Authorization'] = data.access_token;
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: USER_TOKEN_SET, payload: data.access_token });
                    dispatch(getUser(data.token));
                    // window.location = "/customer/dashboard";
                    message.success(data.message);
                } else {
                   // window.location = "/signin";
                    dispatch({type: FETCH_ERROR, payload: data.error});
                    message.error(data.error);
                }
            }).catch(function (error) {
            message.error(error.message);
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //window.location = "/signin";
            // console.log("Error****:", error.message);
        });
    }
};


export const getUser = (token) => {
    return (dispatch) => {
        // if (!token) {
        // 	const token = localStorage.getItem('token');
        // }

        axios.defaults.headers.common["Authorization"] = token;

        dispatch({ type: FETCH_START });
        // let uinputs = { token: token };
        axios
            .post(Config.apiserver + "authuser", null, CisUI().HeaderRequest)
            .then(({ data }) => {
                // console.log("userSignIn: ", data);
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: USER_DATA, payload: data.user });
                    //console.log("UserInfo :"+data.user.name);
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.error });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: UPDATE_LOAD_USER, payload: false });
                console.log("Error****:", error.message);
            });
    };
};

export const userSignOut = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });

        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("otpstatus");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });

        // axios.post('auth/logout').then(({data}) => {
        //   console.log("log out",data)
        //   if (data.result) {
        //     localStorage.removeItem("token");
        //     dispatch({type: FETCH_SUCCESS});
        //     dispatch({type: SIGNOUT_USER_SUCCESS});
        //   } else {
        //     dispatch({type: FETCH_ERROR, payload: data.error});
        //   }
        // }).catch(function (error) {
        //   dispatch({type: FETCH_ERROR, payload: error.message});
        //   console.log("Error****:", error.message);
        // });
    };
};

export const getUserProfileData = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .get(Config.apiserver + "settings/showprofile/" + id)
            .then((res) => {
                if (res.data.status === 1) {
                    console.log("getUserProfileData: ", res.data.data);
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: USER_PROFILE_DATA,
                        payload: res.data.data,
                    });
                    //console.log("UserInfo :"+data.user.name);
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.error });
                }
            })
            .catch((error) => {
                console.log("Error****:", error.message);
            });
    };
};

export const updateProfile = (values, id) => {
    return (dispatch) => {
        // document.getElementById("loader").style.display = "block";
        axios
            .post(Config.apiserver + "settings/updateprofile/" + id, values)
            .then((res) => {
                if (res.data.status === 1) {
                    // console.log("updateProfile: ", res.data);
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: USER_PROFILE_DATA,
                        payload: res.data.data,
                    });
                    notification.success({
                        message: "Success",
                        type: "success",
                        description: res.data.msg,
                    });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.error });
                    notification.warning({
                        message: "Alert",
                        type: "warning",
                        description: res.data.msg,
                    });
                }

                // document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                console.log("Error****:", error.message);
                // document.getElementById("loader").style.display = "none";
            });
    };
};
