import React, { useEffect } from "react";
import { Button, Col, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { userSignIn } from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authUser = useSelector(({ auth }) => auth.authUser);

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinish = (values) => {
        dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push("/");
        }
    }, [authUser]);

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-wid" style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // height: "100px"
                            height: "100%"
                        }}>
                            <img
                                alt="example"
                                src="/assets/coplogo_blue.png"
                                style={{ width: "100px", height: "100px" }}
                            />
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <Form
                            initialValues={{ remember: true }}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0"
                        >
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "The input is not valid E-mail!",
                                        },
                                    ]}
                                    name="email"
                                >
                                    <Input placeholder="User Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your Password!",
                                        },
                                    ]}
                                    name="password"
                                >
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={17}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        className="gx-mb-0"
                                        htmlType="submit"
                                    >
                                        <IntlMessages id="app.userAuth.signIn" />
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Form>
                    </div>
                    <InfoView />
                </div>
            </div>
        </div>
    );
};

export default SignIn;
