import axios from "axios";
import config from "./config";
import * as https from "https";
// import secureLocalStorage from "react-secure-storage";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const AxiosInstance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: config.apiserver,
  headers: {
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use(function (config) {
  // config.headers.Authorization = secureLocalStorage.getItem("token");
  config.headers.Authorization = localStorage.getItem("token");
  return config;
});

export default AxiosInstance;

export const headers = {
  Authorization: localStorage.getItem("token"),
};
