import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Row } from "antd";

import { hideMessage, userSignInByToken } from "../appRedux/actions";
import Loader from "../components/Loader";
import quote from "./quote.json";

const EmsLogin = () => {
    const dispatch = useDispatch();
    const { showMessage, authUser } = useSelector(({ auth }) => auth);
    const loading = useSelector(({ common }) => common.loading);
    const history = useHistory();
    const [quotation, setQuotation] = useState(0);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            history.push("/");
        }
    });

    useEffect(() => {
        const a = setInterval(() => {
            setQuotation((prevState) => prevState + 1);
        }, 10000);
        return () => clearInterval(a);
    }, []);

    useEffect(() => {
        if (quotation === quote.length) {
            setQuotation(0);
        }
    }, [quotation]);

    useEffect(() => {
        dispatch(userSignInByToken(token));
    }, [token]);

    return (
        <>
            {loading && (
                <div className="gx-loader-view gx-loader-position">
                    <Loader />
                </div>
            )}
            <Row className="signin" style={{ margin: "0" }}>
                <Col xs={24} lg={24}>

                </Col>
            </Row>
        </>
    );
};

export default EmsLogin;
