import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Layout,
    Card,
    Avatar,
    Button,
    Collapse,
    Col,
    Row,
    Form,
    notification,
    Tag,
    Upload,
    message,
} from "antd";
import { Link } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { EditOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import AxiosInstance from "../../util/AxiosInstance";
import { UploadOutlined } from "@ant-design/icons";
const { Meta } = Card;
const { Panel } = Collapse;

const ViewTicket = (props) => {
    const marked = window.marked;
    const [ticket, setTicket] = useState([]);
    console.log("🚀 ~ ViewTicket ~ ticket:", ticket);
    const [tickets, setTickets] = useState([]);
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [form] = Form.useForm();
    // let userData = useSelector((state) => state.auth.profileData);
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(userData);
    let id = props.match.params.id;
    const getTicket = () => {
        setLoading(true);
        axios
            .get(
                config.apiserver + "customersupport/customersupportedit/" + id,
                CisUI().HeaderRequest
            )
            .then((res) => {
                console.log(res.data.data);
                setTicket(res.data.data);
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getTicket();
    }, [status]);

    const onFinish = (values) => {
        // console.log(values);
        setLoading(true);

        const data = {
            ...values,
            // body: ckeEdit,
            support_id: id,
            subject: ticket.subject,

            file: values.file.fileList[0]?.originFileObj,
        };

        const formD = new FormData();
        for (let i in data) {
            formD.append(i, data[i]);
        }

        axios
            .post(
                config.apiserver + `reply/newreply`,
                formD,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    notification.success({
                        message: "Success",
                        type: "success",
                        description: res.data.msg,
                    });

                    setFlag(!flag);
                    form.resetFields();
                } else {
                    // console.log(res.data.msg);
                    setLoading(false);
                    notification.warning({
                        message: "warning",
                        type: "error",
                        description: res.data.msg,
                    });
                }
            })
            .catch((errors) => {
                setLoading(false);
                notification.error({
                    message: "Error",
                    type: "error",
                    description: "Something is Wrong",
                });
            });
    };

    const getTickets = () => {
        setLoading(true);
        axios
            .get(
                config.apiserver + "reply/replylist/" + id,
                CisUI().HeaderRequest
            )
            .then((res) => {
                setLoading(false);
                console.log("tickets");
                console.log(res.data.data);
                setTickets(res.data.data);
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getTickets();
    }, [flag]);

    const genExtra = () => <EditOutlined />;

    const changeStatus = () => {
        setLoading(true);
        AxiosInstance.post(
            config.apiserver + "customersupport/changestatus/" + id
        )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    notification.success({
                        message: "Success",
                        type: "success",
                        description: res.data.msg,
                    });
                    setStatus(1);
                } else {
                    setLoading(false);
                    notification.warning({
                        message: "warning",
                        type: "error",
                        description: res.data.msg,
                    });
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    const createDate = (orgianlDate) => {
        var date = new Date(orgianlDate);
        console.log(moment().format(orgianlDate));
        date = date.toDateString() + " " + date.toLocaleTimeString("en-US");
        // toString
        return date;
    };

    const changeHandler = (e, name = null) => {
        console.log("🚀 ~ changeHandler ~ e:", e);
    };
    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">
                            Ticket {ticket?.ticket_no} - {ticket?.subject}
                        </h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Support Ticket</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Ticket - {ticket?.ticket_no}
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <Content>
                            <Card bordered={false}>
                                {ticket.status === 1 ? (
                                    <Button className="close-btn" danger>
                                        Closed
                                    </Button>
                                ) : (
                                    <Button
                                        className="close-btn"
                                        type="primary"
                                        onClick={changeStatus}
                                    >
                                        Close
                                    </Button>
                                )}
                                <Meta
                                style={{height: "50px"}}
                                    avatar={
                                        <Avatar
                                            size={44}
                                        >
                                            {userData?.name?.charAt(0)}
                                        </Avatar>
                                    }
                                    title={ticket?.customer_name || "Customer Name"}
                                    description={ticket?.customer_email || "Customer Email"}
                                />

                                <Card
                                    type="inner"
                                    bordered={false}
                                    className="view-ticket-details"
                                >
                                    {ticket && ticket?.details && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: marked.parse(
                                                    ticket?.details
                                                ),
                                            }}
                                        />
                                    )}
                                    <p>
                                        File Attachment:
                                        {ticket?.file && (
                                            <a
                                                href={
                                                    `${config.ticket_file}` +
                                                    ticket?.file
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {ticket?.file}
                                            </a>
                                        )}
                                    </p>
                                </Card>
                            </Card>
                        </Content>
                        <Collapse style={{ marginBottom: "20px" }}>
                            <Panel
                                showArrow={false}
                                header="Reply"
                                key="1"
                                extra={genExtra()}
                            >
                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                    className="cis_form"
                                    layout="vertical"
                                >
                                    <Row gutter={[24]} className="cis_form_row">
                                        <Col lg={24} xs={24}>
                                            {/* <Form.Item
                                                label="Message"
                                                className="form-lblock"
                                            >
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={ckeEdit}
                                                    events={{
                                                        change: handleChange,
                                                    }}
                                                />
                                            </Form.Item> */}
                                            <Form.Item
                                                label="Message"
                                                name="body"
                                                valuePropName="data"
                                                getValueFromEvent={(
                                                    event,
                                                    editor
                                                ) => {
                                                    const data =
                                                        editor.getData();
                                                    return data;
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "This field is required!",
                                                    },
                                                ]}
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "|",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "|",
                                                            "link",
                                                            "blockQuote",
                                                            "insertTable",
                                                            "|",
                                                            "undo",
                                                            "redo",
                                                        ],
                                                        mediaEmbed: false,
                                                        image: false,
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col lg={24} xs={24}>
                                            <Form.Item
                                                name="file"
                                                label="File Attachment"
                                            >
                                                <Upload
                                                    onChange={(e) =>
                                                        changeHandler(
                                                            e,
                                                            "image"
                                                        )
                                                    }
                                                    beforeUpload={(file) => {
                                                        const isJpgOrPng =
                                                            file.type ===
                                                                "image/jpg" ||
                                                            file.type ===
                                                                "image/jpeg" ||
                                                            file.type ===
                                                                "image/png" ||
                                                            file.type ===
                                                                "application/pdf" ||
                                                            file.type ===
                                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                            file.type ===
                                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                            file.type ===
                                                                "application/vnd.ms-excel" ||
                                                            file.type ===
                                                                "application/msword" ||
                                                            file.type ===
                                                                "text/csv";

                                                        if (!isJpgOrPng) {
                                                            message.error(
                                                                "You can only upload jpg, jpeg, png, pdf, doc, docx, xls, xlsx, csv file!"
                                                            );
                                                            return true;
                                                        }
                                                        return false;
                                                    }}
                                                    maxCount={1}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Click to Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>

                                        <Col
                                            lg={24}
                                            xs={24}
                                            style={{
                                                textAlign: "center",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Button
                                                type="secondary"
                                                htmlType="reset"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Panel>
                        </Collapse>
                        {/* )} */}
                        {tickets.map((reply, index) => (
                            <Card
                                key={index}
                                size="small"
                                bordered={false}
                                className="reply-list"
                                title={`Posted By ${
                                    reply?.reply_admin || reply?.reply_customer
                                } on ${createDate(reply?.created_at)}`}
                                extra={
                                    reply.user_id === 1 ? (
                                        <Tag color="#108ee9">Operator</Tag>
                                    ) : (
                                        <Tag color="#87d068">Owner</Tag>
                                    )
                                }
                            >
                                <div className="reply-body">
                                    {reply && reply?.body && (
                                        <p
                                            className="ant-card-meta-title subject"
                                            dangerouslySetInnerHTML={{
                                                __html: marked.parse(
                                                    reply.body
                                                ),
                                            }}
                                        ></p>
                                    )}
                                    <p>
                                        File Attachment:
                                        {reply?.file && (
                                            <a
                                                href={
                                                    `${config.reply_file}` +
                                                    reply?.file
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {reply?.file}
                                            </a>
                                        )}
                                    </p>
                                </div>
                            </Card>
                        ))}
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default ViewTicket;
