import React, { useState } from "react";
import {
    Breadcrumb,
    Input,
    Layout,
    Card,
    Menu,
    Button,
    Dropdown,
    Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { CisUI, GetStatus } from "../../util/CISUI";
import {
    EllipsisOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { DeleteService } from "../../appRedux/actions/Service";
import AxiosInstance from "../../util/AxiosInstance";
import { useQuery } from "react-query";

const ServiceList = () => {
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const userData = JSON.parse(CisUI().getLocalUserData());
    const publishDate = (orgianlDate) => {
        var date = new Date(orgianlDate);
        date = date.toDateString();
        return date;
    };

    const { data: services, isLoading } = useQuery(
        ["service/servicelist"],
        () =>
            AxiosInstance.get("service/servicelist").then(
                (res) => res.data.data
            )
    );

    const handleSearch = (e) => {
        const salesproduct = [];

        services.forEach((service, i) => {
            if (
                service.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            ) {
                salesproduct.push({
                    key: service.id,
                    sl: ++i,
                    service: service.name,
                    pricing: service.price,
                    duedate: publishDate(service.created_at),
                    status: CisUI().getStatusLabel(service.status),
                    actions: (
                        <Dropdown
                            overlay={actionMenu(service.id)}
                            placement="bottomCenter"
                        >
                            <Button className="list-table-btn" size="small">
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    ),
                });
            }
        });
        setTableData(salesproduct);
    };

    // const columns = [
    //     { title: "SL", dataIndex: "sl", key: "sl" },
    //     { title: "Product/Service", dataIndex: "service", key: "service" },
    //     {
    //         title: "Status",
    //         dataIndex: "status",
    //         key: "status",
    //         render: (status, item) => console.log(item?.status),
    //     },
    // ];

    const columns = [
        { title: "Service", dataIndex: "name", key: "name" },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => GetStatus(status),
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "id",
            render: (id) => (
                <Dropdown overlay={actionMenu(id)} placement="bottomCenter">
                    <Button className="list-table-btn" size="small">
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    // const getTableData = () => {
    //     const data = [];

    //     services?.map((service, i) => {
    //         data.push({
    //             key: service.id,
    //             sl: ++i,
    //             service: service.name,
    //             pricing: service.price,
    //             duedate: publishDate(service.created_at),
    //             status: service.status,
    //             actions: (
    //                 <Dropdown
    //                     overlay={actionMenu(service.id)}
    //                     placement="bottomCenter"
    //                 >
    //                     <Button className="list-table-btn" size="small">
    //                         <EllipsisOutlined />
    //                     </Button>
    //                 </Dropdown>
    //             ),
    //         });
    //     });
    //     setTableData(data);
    // };

    // useEffect(() => {
    //     getTableData();
    // }, [services]);

    const actionMenu = (id) => {
        const menu = (
            <Menu>
                <Menu.Item key={`edit_` + id}>
                    <Link to={`/admin/service/editservice/${id}`}>
                        <Button
                            size="small"
                            type="primary"
                            className="list-table-btn"
                            block
                        >
                            Edit
                        </Button>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`delete_` + id}>
                    <Button
                        size="small"
                        type="primary"
                        className="list-table-btn"
                        block
                        onClick={() => {
                            showConfirm(id);
                        }}
                    >
                        Delete
                    </Button>
                </Menu.Item>
            </Menu>
        );
        return menu;
    };

    function showConfirm(serviceID) {
        Modal.confirm({
            title: "Do you Want to delete these items?",
            content: "Some descriptions",
            okText: "Ok",
            cancelText: "Cancel",
            onOk() {
                dispatch(DeleteService(serviceID));
            },
            onCancel() {
                console.log("Cancel");
            },
        });
        console.log(serviceID);
    }

    return (
        // loading ? <Loading /> :
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        {userData.area === 1 ? (
                            <h1 className="home-top-header">
                                Services
                                <Link to="./addservice">
                                    <Button
                                        className="title-btn"
                                        icon={<PlusOutlined />}
                                    />
                                </Link>
                            </h1>
                        ) : (
                            <h1 className="home-top-header">Services</h1>
                        )}
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Services</Breadcrumb.Item>
                        </Breadcrumb>

                        <Card
                            extra={
                                <Input
                                    onChange={handleSearch}
                                    size="large"
                                    placeholder="Search Service"
                                    addonAfter={<SearchOutlined />}
                                />
                            }
                        >
                            <Table
                                scroll={{ x: true }}
                                columns={columns}
                                dataSource={services}
                                loading={isLoading}
                                size="small"
                            />
                        </Card>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default ServiceList;
