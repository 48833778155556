import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Col,
    Input,
    Layout,
    Row,
    Select,
    Form,
    notification,
    Button,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";

const { Option } = Select;
const { TextArea } = Input;

const EditService = (props) => {
    const [form] = Form.useForm();
    const serviceID = props.match.params.id;
    const history = useHistory();
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        // console.log(values);

        axios
            .post(
                config.apiserver + `service/serviceupdate/${serviceID}`,
                values,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    notification.success({
                        message: "Success",
                        description: res.data.msg,
                    });
                    history.push("../servicelist");
                } else {
                    setLoading(false);
                    notification.warning({
                        message: "Something is Wrong",
                        description: res.data.msg,
                    });
                    history.push("../servicelist");
                }
            })
            .catch((errors) => {
                setLoading(false);
                notification.error({
                    message: "Error",
                    description: "Service not Edit",
                });
                console.log(errors);
            });
    };

    const getSingleService = () => {
        setLoading(true);
        axios
            .get(
                config.apiserver + `service/serviceedit/${serviceID}`,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    setService(res.data.data);
                    console.log(res.data.data);
                } else {
                    setLoading(false);
                    console.log("error");
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getSingleService();
    }, []);

    useEffect(() => {
        if (serviceID) {
            form.setFieldsValue({
                name: service.name,
                type: service.type,
                price: service.price,
                status: service?.status?.toString(),
                details: service.details,
            });
        }
    }, [form, service, serviceID]);

    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Edit Service</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>Edit Service</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                {service === "" ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onFinish={onFinish}
                                        form={form}
                                        className="cis_form"
                                        layout="vertical"
                                    >
                                        <Row gutter={[24]}>
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Name is required",
                                                        },
                                                    ]}
                                                    name="name"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Service Status"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Service Status is required",
                                                        },
                                                    ]}
                                                    name="status"
                                                    className="form-lblock"
                                                >
                                                    <Select
                                                        placeholder="Select An Option"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Option value="1">
                                                            Active
                                                        </Option>
                                                        <Option value="0">
                                                            Inactive
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                lg={24}
                                                xs={24}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    type="secondary"
                                                    htmlType="reset"
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default EditService;
