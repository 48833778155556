import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Col,
    Input,
    Layout,
    Row,
    Select,
    Form,
    Upload,
    message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Button } from "antd";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import { MenuOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomer } from "../../appRedux/actions/Customer";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import AxiosInstance from "../../util/AxiosInstance";
import { useQuery } from "react-query";

const { Option } = Select;
const OpenTicket = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { authUser } = useSelector(({ auth }) => auth);

    const dispatch = useDispatch();
    let customers = useSelector(({ customer }) => customer.customers);

    // const [services, setServices] = useState([]);

    const { data: services, isLoading } = useQuery(
        ["service/servicelist"],
        () =>
            AxiosInstance.get("service/servicelist").then(
                (res) => res.data.data
            )
    );

    const [loading, setLoading] = useState(false);

    // const getServices = () => {
    //     axios
    //         .get(
    //             config.apiserver + "service/servicelist",
    //             CisUI().HeaderRequest
    //         )
    //         .then((res) => setServices(res.data.data))
    //         .catch((errors) => console.log(errors));
    // };

    useEffect(() => {
        dispatch(getAllCustomer());
        // getServices();
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        const data = {
            ...values,
            customer_id: authUser?.id,
            file: values?.file?.fileList[0]?.originFileObj,
            department: "Technical Support",
        };

        const formD = new FormData();
        for (let i in data) {
            formD.append(i, data[i]);
        }

        axios
            .post(
                config.apiserver + `customersupport/newcustomersupport`,
                formD,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    CisUI().Notification("success", res.data.msg);
                    history.push("./all-request");
                } else {
                    setLoading(false);
                    CisUI().Notification("warning", res.data.msg);
                }
            })
            .catch((errors) => {
                setLoading(false);
                CisUI().Notification("error", "Ticket Not Created");
            });
    };

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem("userData"));
        if (profile) {
            form.setFieldsValue({
                name: profile.name,
                email: profile.email,
            });
        }
    }, [form]);

    const changeHandler = (e, name = null) => {
        console.log("🚀 ~ changeHandler ~ e:", e);
    };

    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">
                            Open Ticket
                            <Link to="./all-request">
                                <Button
                                    className="title-btn"
                                    icon={<MenuOutlined />}
                                />
                            </Link>
                        </h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Submit Ticket</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                    className="cis_form"
                                    layout="vertical"
                                >
                                    <Row gutter={[24]} className="cis_form_row">
                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Name is required",
                                                    },
                                                ]}
                                                name="name"
                                                className="form-lblock"
                                            >
                                                <Input
                                                    placeholder="Name"
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Email is required",
                                                    },
                                                ]}
                                                name="email"
                                                className="form-lblock"
                                            >
                                                <Input
                                                    placeholder="Email"
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Subject"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Subject is required",
                                                    },
                                                ]}
                                                name="subject"
                                                className="form-lblock"
                                            >
                                                <Input placeholder="Subject" />
                                            </Form.Item>
                                        </Col>

                                        {/* <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Support Group"
                                                name="department"
                                                className="form-lblock"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Support Group is required",
                                                    },
                                                ]}
                                                initialValue="technical-support"
                                            >
                                                <Select
                                                    placeholder="Select An Option"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Option value="technical-support">
                                                        Technical Support
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col> */}
                                        {authUser?.area === 1 && (
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Customer"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Customer is required",
                                                        },
                                                    ]}
                                                    name="customer_id"
                                                    className="form-lblock"
                                                >
                                                    <Select
                                                        placeholder="Please select an option"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {customers.map(
                                                            (item, index) => (
                                                                <Option
                                                                    key={
                                                                        ++index
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Related Service"
                                                name="service_id"
                                                className="form-lblock"
                                            >
                                                <Select
                                                    placeholder="Select An Option"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    loading={isLoading}
                                                >
                                                    {services?.map(
                                                        (service, index) => (
                                                            <Option
                                                                key={++index}
                                                                value={
                                                                    service.id
                                                                }
                                                            >
                                                                {service.name}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Priority"
                                                name="priority"
                                                className="form-lblock"
                                                initialValue="Medium"
                                            >
                                                <Select
                                                    placeholder="Please select priority"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Option value="High">
                                                        High
                                                    </Option>
                                                    <Option value="Medium">
                                                        Medium
                                                    </Option>
                                                    <Option value="Low">
                                                        Low
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} xs={24}>
                                            {/* <Form.Item
                                                label="Message"
                                                className="form-lblock"
                                            >
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={ckeEdit}
                                                    events={{
                                                        change: handleChange,
                                                    }}
                                                />
                                            </Form.Item> */}
                                            <Form.Item
                                                label="Message"
                                                name="details"
                                                valuePropName="data"
                                                getValueFromEvent={(
                                                    event,
                                                    editor
                                                ) => {
                                                    const data =
                                                        editor.getData();
                                                    return data;
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "This field is required!",
                                                    },
                                                ]}
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "|",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "|",
                                                            "link",
                                                            "blockQuote",
                                                            "insertTable",
                                                            "|",
                                                            "undo",
                                                            "redo",
                                                        ],
                                                        mediaEmbed: false,
                                                        image: false,
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} xs={24}>
                                            <Form.Item
                                                name="file"
                                                label="File Attachment"
                                            >
                                                <Upload
                                                    className=""
                                                    onChange={(e) =>
                                                        changeHandler(
                                                            e,
                                                            "image"
                                                        )
                                                    }
                                                    beforeUpload={(file) => {
                                                        const isJpgOrPng =
                                                            file.type ===
                                                                "image/jpg" ||
                                                            file.type ===
                                                                "image/jpeg" ||
                                                            file.type ===
                                                                "image/png" ||
                                                            file.type ===
                                                                "application/pdf" ||
                                                            file.type ===
                                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                            file.type ===
                                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                            file.type ===
                                                                "application/vnd.ms-excel" ||
                                                            file.type ===
                                                                "application/msword" ||
                                                            file.type ===
                                                                "text/csv";

                                                        if (!isJpgOrPng) {
                                                            message.error(
                                                                "You can only upload jpg, jpeg, png, pdf, docx, xlsx, xls, csv, doc files!"
                                                            );
                                                            return true;
                                                        }
                                                        return false;
                                                    }}
                                                    maxCount={1}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Click to Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            lg={24}
                                            xs={24}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Button
                                                type="secondary"
                                                htmlType="reset"
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};
export default OpenTicket;
