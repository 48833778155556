import React from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../../../components/UserInfo";

import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import { toggleCollapsedSideNav, userSignOut } from "../../../appRedux/actions";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import { LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;

const HorizontalDefault = () => {
    const navCollapsed = useSelector(({ common }) => common.navCollapsed);
    const width = useSelector(({ common }) => common.width);

    const dispatch = useDispatch();

    return (
        <div className="gx-header-horizontal">
            <Header className="gx-header-horizontal-main">
                <div className="gx-container">
                    <div className="gx-header-horizontal-main-flex">
                        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                            <i
                                className="gx-icon-btn icon icon-menu"
                                onClick={() => {
                                    dispatch(
                                        toggleCollapsedSideNav(!navCollapsed)
                                    );
                                }}
                            />
                        </div>
                        <Link
                            to="/"
                            className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo"
                        >
                            <img
                                className="logo-size"
                                alt=""
                                src="/assets/coplogo_blue.png"
                                style={{ width: "80px" }}
                            />
                        </Link>
                        <Link
                            to="/"
                            className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                        >
                            <img
                                className="logo-size-240"
                                alt=""
                                src="/assets/coplogo_blue.png"
                                style={{ width: "80px" }}
                            />
                        </Link>

                        <ul className="gx-header-notifications gx-ml-auto">
                            <li className="gx-user-nav">
                                <UserInfo />
                            </li>
                        </ul>
                    </div>
                </div>
            </Header>

            {width >= TAB_SIZE && (
                <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
                    <div className="gx-container">
                        <div
                            className="gx-header-horizontal-nav-flex"
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                            }}
                        >
                            <HorizontalNav />
                            <ul className="gx-header-notifications gx-ml-auto">
                                <li
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => dispatch(userSignOut())}
                                >
                                    <span className="gx-pointer gx-d-block">
                                        Logout
                                    </span>
                                    <LogoutOutlined />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HorizontalDefault;
