import React from "react";
import { Route, Switch } from "react-router-dom";

import OpenTicket from "../../../containers/Support/OpenTicket";
import TicketList from "../../../containers/Support/TicketList";
import ViewTicket from "../../../containers/Support/ViewTicket";
import EditTicket from "../../../containers/Support/EditTicket";

const Support = ({ match }) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}/all-request`} component={TicketList} />
            <Route
                path={`${match.url}/submit-request`}
                component={OpenTicket}
            />
            <Route
                path={`${match.url}/edit-request/:id`}
                component={EditTicket}
            />
            <Route
                path={`${match.url}/view-request/:id`}
                component={ViewTicket}
            />
        </Switch>
    </div>
);

export default Support;
