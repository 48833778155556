import React, { useState, useEffect } from "react";
import { Card, Col, Table } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { CisUI, GetStatus, GetTicketStatus } from "../../util/CISUI";
import config from "../../util/config";
import { PlusOutlined } from "@ant-design/icons";
import Loading from "../Loading";
import { useSelector } from "react-redux";

const HomeCard = () => {
    const [services, setServices] = useState([]);
    const [customers, setCustomer] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);

    const { authUser } = useSelector(({ auth }) => auth);

    const getServices = () => {
        setLoading(true);
        axios
            .get(config.apiserver + "dashboard", CisUI().HeaderRequest)
            .then((res) => {
                setServices(res.data.latest_services);
                setCustomer(res.data.latest_customers);
                setTickets(res.data.latest_tickets);
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getServices();
    }, []);

    const customerColumns = [
        {
            title: "SL",
            dataIndex: "sl",
            key: "sl",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => GetTicketStatus(status),
        },
    ];

    const serviceColumns = [
       
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => GetStatus(status),
        }
    ];

    const ticketColumns = [
        {
            title: "Ticket No",
            dataIndex: "ticket_no",
            key: "ticket_no",
            render: (ticket_no, record) => (
                <Link to={`./support/view-request/${record.id}`}>
                    {ticket_no}
                </Link>
            ),
        },
        {
            title: "Customer Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
        },
        {
            title: "Support Pin",
            dataIndex: "support_pin",
            key: "support_pin",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => GetTicketStatus(status),
        },
    ];

    return loading ? (
        <Loading />
    ) : (
        <>
            {authUser?.area === 1 ? (
                <>
                    <Col span={24} lg={12}>
                        <Card
                            title="Latest Request"
                            className="home-card"
                            extra={
                                <Link to="/admin/support/submit-request">
                                    <PlusOutlined className="dashboard-icon" />
                                </Link>
                            }
                        >
                            <Table
                                scroll={{ x: true }}
                                size={`small`}
                                columns={ticketColumns}
                                dataSource={tickets}
                                pagination={false}
                            />
                        </Card>
                    </Col>

                    <Col span={24} lg={12}>
                        <Card
                            title="Services"
                            className="home-card"
                            extra={
                                <Link to="/admin/service/addservice">
                                    <PlusOutlined className="dashboard-icon" />
                                </Link>
                            }
                        >
                            <Table
                                scroll={{ x: true }}
                                size={`small`}
                                columns={serviceColumns}
                                dataSource={services}
                                pagination={false}
                            />
                        </Card>
                    </Col>
                </>
            ) : (
                <Col span={24} lg={12}>
                    <Card
                        title="Latest Ticket"
                        className="home-card"
                        extra={
                            <Link to="/customer/support/submit-request">
                                <PlusOutlined className="dashboard-icon" />
                            </Link>
                        }
                    >
                        <Table
                            scroll={{ x: true }}
                            size={`small`}
                            columns={ticketColumns}
                            dataSource={tickets}
                            pagination={false}
                        />
                    </Card>
                </Col>
            )}
        </>
    );
};

export default HomeCard;
